import React, { useState, useContext, useEffect, useRef } from 'react';
import './QuestionInput.css'
import { ChatContext } from '../../../Context/ChatContext';
import { FileContext } from '../../../Context/FileContext';
import useHandleSendMessage from '../../../Hooks/useHandleSendMessage';
import EndButton from '../EndButton/EndButton';
import Select from 'react-select';
import axios from 'axios';

function QuestionInput() {
    const { allMessages, setAllMessages, messageInput, setMessageInput, error, setError, isLoading, setIsLoading } = useContext(ChatContext);
    const { file, setFile, filePreview, setFilePreview, fileError, setFileError, handleUploadClick, handleRemoveFile, showUploadPrompt, setShowUploadPrompt, handleFileChange}  = useContext(FileContext);
    const [isRecordingMic, setIsRecordingMic] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [questionPlaceholder, setQuestionPlaceholder] = useState("Suggested Questions");
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [suggestedQuestions, setSuggestedQuestions] = useState([
        { value: "As an individual, what steps can I take to enhance my concentration during my work?", label: "As an individual, what steps can I take to enhance my concentration during my work?" },
        { value: "As an HR leader, how can I promote a culture that embraces different working styles?", label: "As an HR leader, how can I promote a culture that embraces different working styles?" },
        { value: "As a manager, how can I manage team dynamics to create a supportive and productive workplace?", label: "As a manager, how can I manage team dynamics to create a supportive and productive workplace?" }
    ]);
    const textareaRef = useRef(null);

    const { handleSendMessage } = useHandleSendMessage({
        allMessages, setAllMessages,
        messageInput,setMessageInput,
        filePreview, setFilePreview,
        error, setError,
        file, setFile,
        fileError,
        showUploadPrompt, setShowUploadPrompt,
        isLoading, setIsLoading,
      });

      useEffect(() => {
        const fetchSuggestedQuestions = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/suggested-questions`,
                    { allMessages: allMessages.slice(-10) });
                setSuggestedQuestions(response.data);
            } catch (error) {
                console.error('Error fetching suggested questions:', error);
            }
        };

        if (allMessages.length > 0) {
            fetchSuggestedQuestions();
        }
       
    }, [allMessages]);

    useEffect(() => {
        // Clear selected question when suggested questions change
        if (allMessages.length > 0) {
            setSelectedQuestion(null);
            setQuestionPlaceholder("Updated Suggested Questions")
        }

    }, [suggestedQuestions]);

    const handleSuggestedQuestionSelect = (selectedOption) => {
        setSelectedQuestion(selectedOption);
        const selectedQuestion = selectedOption ? selectedOption.value : '';
        setMessageInput(selectedQuestion);
    };

    const handleMicClick = (e) => { 
        e.preventDefault();

        if (!isRecordingMic) {
            try {
                const recognition = new window.webkitSpeechRecognition(); 
                recognition.lang = 'en-US'; 
                recognition.start(); 
    
                recognition.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    setMessageInput(transcript); 
                };
    
                recognition.onerror = (event) => {
                    console.error('Speech recognition error:', event.error);
                };

                recognition.onend = () => {
                    setIsRecordingMic(false);
                };

                setRecognition(recognition);
                setIsRecordingMic(true);

            } catch (error) {
                console.error('Error initializing speech recognition:', error);
            }
        } else {
            if (recognition) {
                recognition.stop();
                setIsRecordingMic(false);
            }
        }
    }
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage(e);
        }
    }

    const handleStopMessage = (e) => {
        e.preventDefault();
        // if (abortController) {
        //     abortController.abort(); // Stop the ongoing request
        //     console.log('Message generation stopped');
        // }
        // setLoading(false);
    };

    const adjustTextareaHeight = (e) => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = '10px'; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
        }
    };    

    useEffect(() => {
        adjustTextareaHeight(); // Adjust height whenever messageInput changes
    }, [messageInput]);

        // Add event listener to handle window resize
        useEffect(() => {
            const handleResize = () => {
                adjustTextareaHeight();
            };
    
            window.addEventListener('resize', handleResize);
    
            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

    return (
        <div className="question-component">
             <Select
                value={selectedQuestion}
                placeholder={questionPlaceholder}
                options={suggestedQuestions}
                onChange={handleSuggestedQuestionSelect}
                classNamePrefix="suggested-questions"
                menuPlacement="top"
                isClearable
            />

            <div className="type-your-question">
                <form className="question-bar" onKeyPress={handleKeyPress}>
                    <button className="plus2-button">
                            <img src="/plus2.png" className="plus2-image" onClick={handleUploadClick}/>
                    </button>
                    <input  id="file-input" type="file"  onChange={(e) => handleFileChange(e)}/>

                {filePreview && 
                    <div className="file-preview-container">
                            <div className="file-container">
                                <img src="/file-icon-p.png" className="file-icon"/>
                                <p className="file-name">{filePreview}</p>
                            </div>
                                
                        
                        <div className="remove-file">
                            <button className="remove-button" onClick={handleRemoveFile}>X</button>
                        </div>
                    </div>            
                    }

                    <textarea
                        ref={textareaRef}
                        className="q-input"
                        placeholder="Type your question here"
                        value={messageInput}
                        onChange={(e) => { 
                            setMessageInput(e.target.value);
                             adjustTextareaHeight();
                            }}
                          rows="1" 
                    />

                    <button className="mic2-button" onClick={handleMicClick}>
                        <img src={isRecordingMic ? "/mic-rec.png" : "/microphone2.png"} className="mic2-image"/>
                    </button>

                    {isLoading ? (
                        <>
                            <button className="stop2-button" onClick={(e) => handleStopMessage(e)}>
                            <img src="/stop.png" alt="" className="stop2-image" />
                        </button>
                        </>
                    ) 
                    : 
                    (
                        <>
                        <button type="submit" className="submit2-button" onClick={(e) => handleSendMessage(e)}>
                            <img src="/send2.png" alt="" className="submit2-image" />
                        </button>
                        </>
                    )
                    
                    }
                    {/* <button type="submit" className="submit2-button" onClick={(e) => handleSendMessage(e)}>
                        <img src="/send2.png" alt="" className="submit2-image" />
                    </button> */}
                </form>

            </div>
            <EndButton />
        </div>
    );
}

export default QuestionInput;




