import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Hero from '../../Components/HomeComponents/Hero/Hero';
import Features from '../../Components/HomeComponents/Features/Features';
import Footer from '../../Components/HomeComponents/Footer/Footer';
import './Home.css';

function Home() {
    return (
        <div className ="Home">
            <NavBar />
            <Hero />
            <Features />
            <Footer />
        </div>
    );
}

export default Home;
