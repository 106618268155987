import React from 'react';
import './PrivacyPolicy.css'
import NavBar from '../../Components/NavBar/NavBar';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

const PrivacyNotice = () => {
    const location = useLocation();

    // Scrolls to contact section when navigating from footer link
    useLayoutEffect(() => {
        // Scroll to the element with the id matching the hash
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className="privacy-page">
        <NavBar />
        <div className="privacy-container">
            <div className="privacy-content">
                <h1 className="privacy-title">Privacy Policy</h1>

                <p className="privacy-intro">
                    At <strong className="mentelia">Mentelia</strong>, we value your privacy and are committed to protecting your personal
                    information. This privacy policy outlines how we collect, use, and disclose your information when
                    you interact with our AI chatbot designed to provide mentorship and support for neurodiverse
                    individuals in the workplace.
                </p>

                <section className="privacy-section">
                    <h2 className="section-title">1. Personal Information We Collect</h2>

                    <h3 className="subsection-title">Personal Information You Provide:</h3>
                    <ul className="list">
                        <li><strong>Contact Details:</strong> Information such as your name, email address, and phone number when you create an account or communicate with us.</li>
                        <li><strong>Survey Responses:</strong> Information from initial surveys assessing your workplace feelings and experiences to provide personalized support.</li>
                        <li><strong>Feedback and Communications:</strong> Any communications you have with us, including feedback and inquiries.</li>
                    </ul>

                    <h3 className="subsection-title">Automatic Data Collection:</h3>
                    <ul className="list">
                        <li><strong>Device Information:</strong> Details about your device and its operating system, browser type, IP address, and location data.</li>
                        <li><strong>Usage Data:</strong> Information about your interaction with our chatbot, including pages visited, time spent, and actions taken within the service.</li>
                    </ul>

                    <h3 className="subsection-title">Information from Third Parties:</h3>
                    <ul className="list">
                        <li><strong>Social Media Information:</strong> If you interact with us through social media platforms, we may collect information from those platforms.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">2. How We Use Personal Information</h2>
                    <ul className="list">
                        <li><strong>To Provide Our Services:</strong> To operate and maintain the chatbot and provide personalized advice and support.</li>
                        <li><strong>To Communicate with You:</strong> To respond to your inquiries, send updates, and offer support related to our services.</li>
                        <li><strong>To Improve Our Services:</strong> To analyze usage patterns, enhance user experience, and develop new features.</li>
                        <li><strong>For Research and Development:</strong> To conduct research and improve our AI’s capabilities in providing personalized support.</li>
                        <li><strong>For Compliance and Protection:</strong> To adhere to legal requirements and protect against unauthorized access or misuse.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">3. How We Disclose Personal Information</h2>
                    <ul className="list">
                        <li><strong>At Your Direction:</strong> Any third parties you authorize us to share your information with.</li>
                        <li><strong>Service Providers:</strong> Trusted vendors that assist us in operating our services.</li>
                        <li><strong>Legal Authorities:</strong> When required by law or to protect our rights and safety.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">4. Your Privacy Rights and Choices</h2>
                    <p className="section-paragraph">
                        For California Residents: Under the California Consumer Privacy Act (CCPA), you have the right to:
                    </p>
                    <ul className="list">
                        <li><strong>Request Information:</strong> About the categories and specific pieces of personal information we have collected.</li>
                        <li><strong>Request Deletion:</strong> Of your personal information.</li>
                        <li><strong>Opt-Out of Sale:</strong> If applicable.</li>
                    </ul>

                    <p className="section-paragraph">
                        For EU Residents: Under the General Data Protection Regulation (GDPR), you have the right to:
                    </p>
                    <ul className="list">
                        <li><strong>Access Your Data:</strong> And request correction of inaccurate information.</li>
                        <li><strong>Request Erasure:</strong> Of your personal information under certain conditions.</li>
                        <li><strong>Data Portability:</strong> And receive your personal information in a structured format.</li>
                        <li><strong>Object and Restrict Processing:</strong> Under certain conditions.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">5. Security of Your Information</h2>
                    <p className="section-paragraph">We implement reasonable technical and organizational measures to protect your personal information from unauthorized access and misuse. However, no method of transmission over the Internet or electronic storage is completely secure.</p>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">6. Children’s Privacy</h2>
                    <p className="section-paragraph">Project IQ is not intended for users under 16 years of age. If we become aware that we have collected personal information from a child under 16 without parental consent, we will take steps to delete that information.</p>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">7. International Data Transfers</h2>
                    <p className="section-paragraph">Your personal information may be transferred to and processed in jurisdictions outside your location, which may not provide the same level of data protection. We ensure that appropriate safeguards are in place to protect your information, including relying on standard contractual clauses approved by the European Commission.</p>
                </section>

                <section className="privacy-section">
                    <h2 className="section-title">8. Changes to This Privacy Policy</h2>
                    <p className="section-paragraph">We reserve the right to update this privacy policy. We will notify you of any significant changes through our platform or via email.</p>
                </section>

                <section id="contact" className="privacy-section">
                    <h2 className="section-title">9. Contact Us</h2>
                    <p className="section-paragraph">If you have any questions or concerns about this privacy policy or our privacy practices, please contact us at <span className="contact-email">kabhari@sdsu.edu</span>.</p>
                </section>
            </div>
        </div>
        </div>

    );
};

export default PrivacyNotice;