import React from 'react';
import './TermsAndConditions.css'
import NavBar from '../../Components/NavBar/NavBar';


const PrivacyNotice = () => {
    return (
        <div className="terms-page">
        <NavBar />
        <div className="terms-container">
            
            <div className="terms-content">
                <h1 className="terms-title">Terms and Conditions</h1>
                <p className="terms-paragraph">
                    Lorem ipsum odor amet, consectetuer adipiscing elit. Congue imperdiet sapien libero elementum
                    lacinia amet justo natoque. Consectetur lacinia sit montes lacus diam. Magna dictumst at dis,
                    euismod nunc feugiat dapibus turpis. Libero ornare parturient ridiculus conubia tristique turpis.
                    Placerat nisi sem suspendisse porttitor praesent etiam. Scelerisque ornare rutrum lectus; purus
                    integer tristique metus condimentum. Nulla vel nam luctus magnis purus. Felis arcu maecenas
                    ullamcorper lectus ultricies himenaeos. Semper vestibulum euismod curabitur odio ante mus dignissim
                    porttitor nisi. Nisi adipiscing neque tempus hac, accumsan fringilla vitae. Varius gravida
                    ridiculus; lacinia sem ante platea lobortis dictumst. Hendrerit primis purus dis pretium lobortis
                    amet. Praesent sed venenatis aenean eu congue; non libero cubilia euismod. Rhoncus fames montes a
                    sem ex blandit arcu donec bibendum? Elit dui curae volutpat accumsan tortor metus feugiat. Quam
                    laoreet netus ex facilisis tortor venenatis finibus aenean. Tempus eget lectus posuere condimentum
                    ultricies interdum. Malesuada pulvinar phasellus eleifend lacinia ultrices odio nec eu. Fermentum
                    hac suspendisse nascetur cras rutrum sit. Metus leo pharetra egestas tempus pulvinar. Sed class
                    lacinia; luctus ultrices sapien libero rhoncus arcu iaculis. Pretium lacinia vehicula placerat
                    turpis nec lobortis proin potenti. Cras ex congue nulla nulla in non vel. Pulvinar nunc convallis
                    volutpat orci vulputate dapibus nunc scelerisque. Per natoque magna volutpat urna vehicula?
                    Convallis mauris per pellentesque rutrum platea scelerisque sodales sapien. Cubilia molestie curae
                    purus conubia porttitor habitasse netus gravida. Finibus himenaeos volutpat nostra faucibus arcu
                    sapien at. Habitant bibendum mattis dapibus orci semper lacinia efficitur purus. Maximus consectetur
                    imperdiet quis facilisi dapibus dictum maecenas natoque. Eu fusce mollis accumsan nisl consectetur.
                    Eu eleifend tellus feugiat litora integer sollicitudin leo himenaeos. Hac consequat facilisi in
                    iaculis justo litora dolor. Arcu consectetur senectus volutpat ornare cursus eleifend tortor non.
                    Rhoncus phasellus dis venenatis interdum ad egestas iaculis penatibus. Curae posuere interdum
                    parturient sodales per. Ac nulla lectus vestibulum malesuada penatibus id. Eget gravida eleifend
                    metus pretium quam turpis convallis; vitae tristique. Adipiscing sollicitudin vestibulum convallis
                    felis blandit pulvinar arcu. Volutpat lobortis dapibus elit torquent turpis ante ligula. Venenatis
                    porta libero diam, vel id quis praesent magna fusce. Phasellus convallis mi eros interdum efficitur
                    nascetur. Ipsum placerat nam et rhoncus integer tortor vulputate. Penatibus volutpat donec placerat;
                    congue nullam libero quam. Commodo proin iaculis at curabitur accumsan praesent montes condimentum?
                    Sollicitudin parturient mi sed vulputate nec torquent. Id natoque penatibus vehicula elementum
                    sociosqu scelerisque pulvinar. Aptent vivamus sodales volutpat leo pellentesque quam netus? Senectus
                    augue at magnis platea libero morbi porttitor. Aptent condimentum mollis vivamus dolor porttitor
                    aptent urna amet. Fusce auctor risus suspendisse proin morbi. Adipiscing velit phasellus leo posuere
                    accumsan ornare litora. Leo aliquet dictum pretium pulvinar pellentesque gravida hendrerit. Ac
                    natoque platea pulvinar integer lacinia. Mus adipiscing elit ridiculus; per posuere ullamcorper.
                    Montes quisque suspendisse nisi sit eu arcu urna consectetur. Turpis placerat per fusce viverra sit
                    euismod aptent fermentum cras. Nec efficitur class habitant dis sem aptent feugiat lobortis arcu.
                    Ornare a accumsan eros interdum fringilla fringilla. Cubilia convallis convallis vestibulum lectus
                    ullamcorper elementum et nascetur cubilia. Quis sem fermentum phasellus feugiat sociosqu dapibus
                    torquent hendrerit. Torquent lacinia mattis sem sapien id duis porta. Imperdiet tortor magna
                    fermentum curae urna et vehicula. Vivamus quisque tortor odio tempus taciti sociosqu etiam cursus.
                    Penatibus donec finibus elementum consectetur nec iaculis nullam. Id vulputate himenaeos lobortis
                    pharetra fames nullam varius tortor. Donec blandit in dui pulvinar aenean. Nisi elit venenatis
                    phasellus varius justo praesent cras interdum ridiculus. Accumsan est mattis nisi dignissim
                    vestibulum fusce congue sapien. Est conubia diam; placerat fringilla interdum turpis. Adipiscing
                    vestibulum nisi dictum nec eros tristique. Vehicula tellus diam mus risus montes pulvinar tortor
                    tempor sodales. Ultrices aliquam iaculis elit erat neque sagittis elementum sed curabitur. Euismod
                    porta sollicitudin scelerisque egestas, phasellus molestie ridiculus conubia. Vehicula finibus
                    fringilla sem gravida enim. Neque metus condimentum morbi libero mattis volutpat dignissim lectus.
                    Porta scelerisque sociosqu auctor finibus leo iaculis blandit. Congue facilisis justo donec suscipit
                    vestibulum scelerisque vivamus. Facilisis dis egestas justo phasellus platea. At dis semper cubilia;
                    sed lobortis mi? Placerat praesent commodo mollis ligula erat, cras quisque sodales. Sed massa
                    turpis pharetra proin efficitur platea. Fringilla molestie primis varius; convallis penatibus leo.
                    Lacus amet odio hendrerit nunc erat gravida habitasse nulla. Nisl vestibulum facilisi arcu id ante
                    purus semper velit. Finibus dis sapien pretium maximus cras. Ante tellus quisque tempus a massa
                    imperdiet. Turpis semper ante class ultrices commodo fermentum orci. Phasellus placerat malesuada
                    nulla sagittis; ultricies porta efficitur. Nulla arcu sodales turpis ornare dapibus vulputate
                    torquent lacus sociosqu. Nullam hac nulla a viverra magna. Proin aliquam diam posuere aptent quis.
                    Ridiculus mi enim montes senectus congue porta aenean egestas. Tortor efficitur tortor arcu eu
                    quisque rutrum maximus. Placerat mollis accumsan tempus convallis fringilla nam mattis sapien amet.
                    Bibendum pharetra maecenas suscipit non curae. Dolor suspendisse pretium facilisi, penatibus
                    interdum magnis tortor? Euismod rhoncus maecenas montes maximus donec conubia vestibulum. Viverra
                    platea taciti platea auctor iaculis nam; donec praesent semper. Semper fringilla class cras dis
                    accumsan bibendum ultrices. Cursus nisi amet purus placerat adipiscing, arcu fringilla nascetur
                    morbi. Laoreet nec sem sollicitudin mus dolor hac congue. Montes elementum nec eleifend mi amet
                    metus magna. Vestibulum maecenas cursus nostra phasellus malesuada vestibulum. Congue feugiat
                    venenatis nullam turpis nibh metus facilisi dictum nam. Montes cras aliquam viverra tellus feugiat
                    odio conubia commodo turpis. Porta in fermentum platea gravida arcu condimentum penatibus integer.
                    Sed turpis magna cursus sodales orci conubia justo. Curae consequat nisl ut aliquam ut et. Arcu
                    consequat auctor vitae ut sem taciti nam libero facilisis. Nam suscipit magna dis fames gravida erat
                    faucibus elementum. Molestie sodales non enim odio mattis ultrices. Sollicitudin aliquam ut at
                    lobortis aliquet! Augue sagittis lacinia cras taciti habitasse vel aptent consequat. Placerat aenean
                    cubilia sem ullamcorper; donec iaculis euismod. Hendrerit ultricies turpis imperdiet imperdiet in
                    taciti tempus maximus. Dictumst nam condimentum tellus ullamcorper est. Leo dis nostra maecenas enim
                    cursus nascetur. Senectus praesent interdum eleifend neque, amet accumsan tempor tempor. Praesent
                    taciti massa mauris cursus blandit semper dui potenti. Ut etiam cursus libero nulla nisl. Tortor
                    senectus aenean luctus mattis, amet duis fames curabitur tellus. Natoque tempor elit tempus fusce
                    nisi ad pellentesque ex. Imperdiet hendrerit facilisis quam mauris congue sapien. Metus platea
                    rutrum ex; pretium aliquam augue pellentesque cursus magnis. Tempus ad pulvinar aenean turpis massa
                    sagittis conubia nascetur? Sollicitudin per vel efficitur dictum ut. Varius venenatis finibus fusce
                    curabitur volutpat commodo ullamcorper tempor. Sit nam leo nisl; pulvinar facilisi potenti dapibus.
                    Duis in lacus venenatis erat molestie pulvinar sem justo. Imperdiet eleifend ac maecenas habitasse;
                    venenatis platea amet. Dictumst accumsan maximus accumsan lobortis ligula. Curae porttitor ad
                    fermentum nascetur diam libero cubilia. Facilisi tempus quis egestas mi ridiculus; fames ultrices
                    fames. Sem varius eleifend ex dignissim; finibus consequat. Metus pharetra sed velit ullamcorper
                    efficitur fusce nisl malesuada. Tellus id ad mauris rhoncus, urna viverra malesuada parturient
                    cursus. Primis sollicitudin justo ullamcorper semper sociosqu platea viverra. Rhoncus nulla
                    efficitur; lorem bibendum mattis platea porta fames dolor. Duis platea elit aenean vivamus maecenas
                    rutrum sem. Ex quisque mus risus quisque laoreet amet interdum iaculis. Conubia dis sem vehicula
                    dapibus sed. Ultrices donec dolor mauris, ut bibendum scelerisque. Accumsan magna nec primis class
                    per consectetur varius. Quis magnis netus iaculis condimentum euismod. Dignissim dui dapibus duis
                    cursus, sagittis rhoncus tincidunt himenaeos odio. Mattis non ridiculus facilisis justo dolor. Morbi
                    iaculis in eros dis vestibulum dis congue. Nulla per et ornare hac diam quisque orci. Auctor quis
                    fermentum luctus mollis mattis natoque est. Porttitor nam nisl ultricies tellus erat. Cras dictumst
                    aenean quis; amet natoque sagittis. Eget aliquet luctus fames eget quam sagittis dolor dolor. Primis
                    erat sagittis habitant lorem tempus, cras imperdiet vitae. Dictum nostra mauris odio morbi netus sed
                    amet aliquam. In felis eleifend massa sit tincidunt ridiculus dolor curae.
                </p>
            </div>
        </div>
        </div>

    );
};

export default PrivacyNotice;