// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-page {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(105deg, #7355a9 -30.00%, #2c1f47 100.00%);
        height: 100vh;
        align-items: center;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
}
.terms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
    
}

.terms-content {
    padding: 50px;
    max-width: 40%;
}


@media (max-width: 1024px) {
    .terms-content {
        max-width: 60%;
    }
}

@media (max-width: 768px) {
    .terms-content {
        max-width: 80%;
    }
}

@media (max-width: 480px) {
    .terms-content {
        max-width: 90%;
    }
}

.terms-title {
    margin-bottom: 8px;
    font-size: 36px;
    font-weight: 500;
    color: white;
    text-wrap: nowrap;

    background: linear-gradient(
        91deg,
        #7cbdde -1.92%,
        #939ad9 36.79%,
        #a27ad5 76.77%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.terms-paragraph{
    color: white;
    display: block;
    text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TermsAndConditions/TermsAndConditions.css"],"names":[],"mappings":"AAAA;IACI;;;;;+DAK2D;QACvD,aAAa;QACb,mBAAmB;QACnB,YAAY;QACZ,gBAAgB;QAChB,kBAAkB;AAC1B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;;AAGA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;;IAEjB;;;;;OAKG;MACD,qBAAqB;MACrB,6BAA6B;MAC7B,oCAAoC;AAC1C;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".terms-page {\n    background: linear-gradient(\n        0deg,\n        rgba(0, 0, 0, 0.2) 0%,\n        rgba(0, 0, 0, 0.2) 100%\n      ),\n      linear-gradient(105deg, #7355a9 -30.00%, #2c1f47 100.00%);\n        height: 100vh;\n        align-items: center;\n        flex-grow: 1;\n        overflow-y: auto;\n        overflow-x: hidden;\n}\n.terms-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: start;\n    \n}\n\n.terms-content {\n    padding: 50px;\n    max-width: 40%;\n}\n\n\n@media (max-width: 1024px) {\n    .terms-content {\n        max-width: 60%;\n    }\n}\n\n@media (max-width: 768px) {\n    .terms-content {\n        max-width: 80%;\n    }\n}\n\n@media (max-width: 480px) {\n    .terms-content {\n        max-width: 90%;\n    }\n}\n\n.terms-title {\n    margin-bottom: 8px;\n    font-size: 36px;\n    font-weight: 500;\n    color: white;\n    text-wrap: nowrap;\n\n    background: linear-gradient(\n        91deg,\n        #7cbdde -1.92%,\n        #939ad9 36.79%,\n        #a27ad5 76.77%\n      );\n      background-clip: text;\n      -webkit-background-clip: text;\n      -webkit-text-fill-color: transparent;\n}\n\n.terms-paragraph{\n    color: white;\n    display: block;\n    text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
