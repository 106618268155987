import React, { useContext, useState, useEffect } from 'react';
import './Profile.css'
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import NavBar from '../../Components/NavBar/NavBar';

function Profile() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [industry, setIndustry] = useState("");
    const [role, setRole] = useState("");
    const [profilePic, setProfilePic] = useState(null); 
    const [previewPic, setPreviewPic] = useState("/user-profile-photo.png");
    const [provider, setProvider] = useState('');
    const {isLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const getUserProfile = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_CHATAGENT_URI}/user/profile`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              },
          });

          if (response.ok) {
              const data = await response.json();
              setFirstName(data.first_name);
              setLastName(data.last_name);
              setEmail(data.email);
              setIndustry(data.industry);
              setRole(data.role);
              setPreviewPic(data.profile_pic != null ? `data:image/jpeg;base64,${data.profile_pic}` : '/user-profile-photo.png');  
              setProvider(data.provider);
          } else {
              console.error('Failed to fetch profile information');
          }
      } catch (error) {
          console.error('Error fetching profile:', error);
      }
  };

    useEffect(() => {
      getUserProfile();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/chatbot');
    }
}, [isLoggedIn]);

const handleReturn = () => {
  navigate(-1); //navigate the user back to the previous page
};

    const handleProfilePicChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
          setProfilePic(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewPic(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };

      const handleRemoveProfilePic = async (e) => {
        e.preventDefault();

        if (previewPic == '/user-profile-photo.png') {
          setProfilePic(null);
          return;
        }

        try {
          const response = await fetch(`${process.env.REACT_APP_CHATAGENT_URI}/user/delete-profile-pic`, {
              method: 'DELETE',
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              },
          });

          setProfilePic(null);
          setPreviewPic("/user-profile-photo.png");

      } catch (error) {
          console.error("Error occurred while updating profile:", error);
          setError('Failed to update profile picture');
      }

      };
          
      const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setSuccess('');

        const formData = new FormData();
        if (firstName) formData.append('firstName', firstName);
        if (lastName) formData.append('lastName', lastName);
        if (email) formData.append('email', email);
        if (industry) formData.append('industry', industry);
        if (role) formData.append('role', role);
        if (profilePic) formData.append('profilePic', profilePic)

        console.log(profilePic);

        try {
            const response = await fetch(`${process.env.REACT_APP_CHATAGENT_URI}/user/profile`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                console.log("Profile updated successfully:", data);
                setSuccess('Profile updated successfully');
            } else {
                console.error("Failed to update profile:", data);
                setError('Failed to update profile');
            }
        } catch (error) {
            console.error("Error occurred while updating profile:", error);
            setError('Failed to update profile');
        }
    };

    return (
      <div className="profile-page">
        <NavBar />
        <div className="profile-container">
        <div className="return">
          <button onClick={handleReturn} className="return-button"> ← Return</button>
          </div>
          
        <h2 className="profile-title">Edit Profile</h2>
        <form onSubmit={handleSubmit} className="profile-form">
          
          <div className="form-group">
          <div className="form-group-picture">
              { previewPic && <img src={previewPic} alt="Profile" className="profile-pic-preview" />}
              <input type="file" accept="image/*" className="file-input" onChange={handleProfilePicChange} id="uploadInput"/>
              
              <div className="edit-container">
                <label htmlFor="profilePic" className="picture-title">Profile Picture</label>
                <div className="edit-picture">
                  <button onClick={(e) => {e.preventDefault(); document.getElementById('uploadInput').click(); }} className="picture-buttons">Upload</button>
                  <button onClick={handleRemoveProfilePic} className="picture-buttons">Remove</button>
                </div>
              </div>
            </div>
            </div>
          
          <div className="name-group"> 
          <div className="name-form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="name-form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          </div>


          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={provider === 'LinkedIn' || provider === 'Google'}
              className={provider === 'linkedin' || provider === 'google' ? 'input-disabled' : ''}
            />
          </div>

          <div className="form-group">
            <label htmlFor="industry">Industry</label>
            <input
              type="text"
              id="industry"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="role">Role</label>
            <input
              type="text"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>

          <button type="submit" className="save-button">SAVE CHANGES</button>
          {success && <p className="success">{success}</p>}
          {error && <p className="error">{error}</p>}
        </form>
      </div>
      </div>

  
  
    );
  }
  
  export default Profile;