import React, { useState } from 'react';
import './Hero.css';
import PrivacyNotice from '../PrivacyNotice/PrivacyNotice';
import Questions from '../Questions/Questions';


function Hero() {

    const [showNotice, setShowNotice] = useState(false);
    const [destination, setDestination] = useState(null);

    const handleClick = (e, destination) => {
        e.preventDefault();
        setShowNotice(true);
        setDestination(destination);
    };

    const handleClose = () => {
        setShowNotice(false);
    };

    const handleProceed = () => {
        if (destination) {
            window.location.href = destination;
        }
    };

    return (
        <div className="hero">
            {/* <PrivacyNotice showNotice={showNotice} handleClose={handleClose} handleProceed={handleProceed} /> */}
            <div className="hero-content">
                <div className="title-container">
                    <h1 className="gradient-title">Neurodiverse&nbsp;</h1>
                    <h1>Aware AI</h1>
                </div>
                <h2>Empower Workplace Diversity</h2>  
                <h2>With Tailored AI Mentorship</h2>
            </div> 
            <Questions />
        </div>
    );
}

export default Hero;


