import React, { createContext, useState, useEffect, useContext } from 'react';
import { ChatContext } from './ChatContext';
import { FileContext } from './FileContext';
import axios from "axios";
import useUserProfile from '../Hooks/useUserProfile';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { resetSurveyData, setAllMessages, show } = useContext(ChatContext);
    const { profileData, setProfileData } = useUserProfile();

    const userLogin = (surveyData, accessToken, first_name) => {
        setIsLoggedIn(true);
        

        if (surveyData) {
            localStorage.setItem('surveyData', JSON.stringify(surveyData));
        }
        
        localStorage.setItem('firstName', first_name);
        localStorage.setItem('accessToken', accessToken);
        // localStorage.setItem('refreshToken', refreshToken);
    };

    const userLogout = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/logout`);
            console.log('Response from backend:', response);

            if (response.status === 200) {
                // User successfully logged out

                localStorage.removeItem('surveyData');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('firstName');
                if (localStorage.getItem('profilePic')) localStorage.removeItem('profilePic');
                setProfileData(null);
                resetSurveyData();
                setIsLoggedIn(false);

                setAllMessages([]);
                // setShowUploadPrompt(false);
                
                console.log("Log out successful");
            } else {
                console.error('Log out failed');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }

    };

    useEffect(() => {
        const handlePageUnload = () => {
            localStorage.removeItem('surveyData');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('firstName');
            if (localStorage.getItem('profilePic')) localStorage.removeItem('profilePic');
            localStorage.removeItem('privacyAgreed');
            setProfileData(null);
            resetSurveyData();
            setIsLoggedIn(false);
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handlePageUnload);

        // Clean up event listener when component unmounts
        return () => {
            window.removeEventListener('beforeunload', handlePageUnload);
        };
    }, [resetSurveyData]);

    return (
         <AuthContext.Provider value={{ isLoggedIn, isLoading, userLogin, userLogout }}>
            {children}
         </AuthContext.Provider>
    );
};
