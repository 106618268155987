import NavBar from '../../Components/NavBar/NavBar';
import Tokens from '../../Components/ChatbotComponents/Tokens/Tokens';
import './Summary.css'
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { useRef, useState, useEffect, useContext } from 'react';
import { ChatContext } from '../../Context/ChatContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AuthContext } from '../../Context/AuthContext';


function Summary() {
    const [summary, setSummary] = useState({
        question: '',
        advice: '',
        resource1: '',
        resource2: '',
        resource3: ''
    });
    const navigate = useNavigate();
    const summaryRef = useRef(null);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const shareButtonRef = useRef(null);
    const { allMessages } = useContext(ChatContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const isGenerated = useRef(false);
    const {isLoggedIn} = useContext(AuthContext);

    const handleGenerateSummary = async () => {
        try {
            setIsLoading(true);
            setError(false);

            if (allMessages.length === 0) {
                navigate('/chatbot');
                return;
            }

            const filteredMessages = allMessages.map(message => ({
                role: message.role,
                content: message.content
            }))
            if (filteredMessages.length === 0) {
                console.log('No valid messages to summarize');
                setIsLoading(false);
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/summary`, { messages: filteredMessages.slice(-10) });
            const summary_response = response.data;

            setSummary({
                question: summary_response.QUESTION || 'No question summary available.',
                advice: summary_response.ADVICE || 'No advice summary available.',
                resource1: summary_response.RESOURCE1 || 'No resource available.',
                resource2: summary_response.RESOURCE2 || 'No resource available.',
                resource3: summary_response.RESOURCE3 || 'No resource available.'
            });

        } catch (error) {
            console.error('Error generating summary:', error);
            setError('Server error. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isGenerated.current) return;
        isGenerated.current = true;

        handleGenerateSummary();
        console.log("generating summary...");
    }, []); 

    const handleNavigation = () => {
        navigate('/signup');
    };

    const handleSave = async () => {
        const element = summaryRef.current;
        const canvas = await html2canvas(element, {
            scale: 4,
        });
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('mentelia-summary.pdf');
    };

    const handleShare = () => {
        setShowShareOptions(!showShareOptions);
    };

    const summaryEmailText = 

`Hi,

Here is a summary of a recent conversation I had on the Mentelia chatbot, which provided me with valuable insights and resources on workplace inclusivity.
Try it for free at https://mentelia.com for personalized guidance on workplace inclusivity!

Conversation Summary

Your Question:
${summary.question}

Advice:
${summary.advice}

Additional Resources:
    Here are some more resources you may find helpful:
        - ${summary.resource1}
        - ${summary.resource2}
        - ${summary.resource3}
`;

    const handleGmailShare = () => {
        const subject = 'Mentelia Conversation Summary';
        const body = summaryEmailText;
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(gmailUrl, '_blank', 'noopener,noreferrer');
    };

    const handleLinkedInShare = () => {
        const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent('www.mentelia.com')}&title=${encodeURIComponent('Conversation Summary')}&summary=${encodeURIComponent(summaryEmailText)}`;
        window.open(linkedInUrl, '_blank', 'noopener,noreferrer');
    };

    const handleShareClose = (event) => {
        if (shareButtonRef.current && !shareButtonRef.current.contains(event.target)) {
            setShowShareOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleShareClose);
        return () => {
            document.removeEventListener('mousedown', handleShareClose);
        };
    }, []);


    return (
        <>
        <div className ="Summary">
            <NavBar />
            <Tokens tokens={0}/>

            <div className="chatbox">
                <div className="message assistant">
                    <img src="/chatbot-profile-photo.png" className="chat-photos" />
                    <div className="message-bubble-n-s assistant">
                        <p>You have reached the tokens limit or ended the conversation. Here is a summary of our conversation.</p>
                    </div>
                </div>
                <div className="message assistant">
                <div/>
                <div/>
                <div/>

                    {error && (
                        <div className="message assistant" >
                            <div className="message-bubble-n assistant">
                                <p>Server error. Please try again. </p>
                                <div className="retry" onClick={handleGenerateSummary}>
                                    <img src="/refresh.png" className="refresh-image"></img>

                                </div>
                            </div>
                        </div>)}

                    {isLoading && ( 
                        <div className="message-bubble-n-s">
                            <span className="bouncing-dots">
                                <span className="dot">.</span>
                                <span className="dot">.</span>
                                <span className="dot">.</span>
                            </span> 
                            <p>Generating Response</p>
                        </div>
                    )}
                                
                {!isLoading && !error && (
                <div className="message-bubble-n-s assistant" ref={summaryRef}>
                    <div className="summary-content">
                        <div className="summary-title">Conversation Summary</div>
                       
                       <div className="summary-section">
                        <img src="/sum1.png" className="sum-img"/>
                            <div className="summary-h">Your Question</div>
                       </div>

                       <div className="section-content">
                            {summary.question}
                        </div>

                       <div className="summary-section">
                        <img src="/sum2.png" className="sum-img"/>
                            <div className="summary-h">Advice</div>
                        </div>                        
                       
                        <div className="section-content">
                            {summary.advice}
                        </div>

                       <div className="summary-section">
                        <img src="/sum3.png" className="sum-img"/>   
                            <div className="summary-h">Additional Resources</div>
                       </div>

                       <div className="section-content-np">
                                <p className="resources">Here are some more resources you may find helpful.</p>
                                {/* <p className="link">{summary.resource1}</p>
                                <p className="link">{summary.resource2}</p>
                                <p className="link">{summary.resource3}</p> */}
                                    <p className="link" dangerouslySetInnerHTML={{ __html: summary.resource1 }} />
                                    <p className="link" dangerouslySetInnerHTML={{ __html: summary.resource2 }} />
                                    <p className="link" dangerouslySetInnerHTML={{ __html: summary.resource3 }} />
                        </div>
                        <div className="save-share-section">
                        <p className="summary-p">Would you like to save or share your summary?</p>
                            <div className="sum-buttons">
                                <button className="sum-button" onClick={handleSave}>Save
                                    <img src="/sum-save.png" className="sum-save"/>
                                </button>
                            <div className="share-container" ref={shareButtonRef}>
                                 <button className="sum-button" onClick={handleShare}>Share
                                    <img src="/sum-share.png" className="sum-share" />
                                </button>
                                {showShareOptions && (
                                    <div className="share-options">
                                        <button className="share-option" onClick={handleGmailShare}>Share via Gmail
                                            <img src="/gmail-icon.png" className="share-icon"></img>
                                        </button>
                                        <button className="share-option" onClick={handleLinkedInShare}>Share via LinkedIn
                                            <img src="/linkedin-icon.png" className="share-icon"></img>
                                        </button>
                                    </div>
                                  )}
                            </div>
                            </div>
                    </div>
                    </div>
                </div>
                )}     
            </div>
    
            </div>

        {!isLoggedIn && (
            <div className="Sign-up-to-continue">
                <div className="sign-up-content">
                    <div className="sign-up-title">Sign up to continue</div>
                    <p className="sign-up-p">
                         Delve deeper into topics, receive personalized assistance, and engage in confidential 
                         conversations tailored to your needs.</p>
                         <div className="summary-buttons">
                            {/* <button className="summary-button">Subscribe</button> */}
                            <button className="summary-button" onClick={handleNavigation}>Create Account</button>
                         </div>
                </div>
            </div>
            )   
        }

        </div>
        </>
    );
}

export default Summary;